// React
import { Fragment, ReactElement, useState } from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, useAccordionItemContext } from '@reach/accordion';
// Next
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
// Global
import { v4 as uuidv4 } from 'uuid';
import isEmpty from 'lodash.isempty';
// System Design
import { Button } from '@utmb/design-system/button/Button';
import { Col } from '@utmb/design-system/box/Box';
import { Font } from '@utmb/design-system/text/font';
import { Icon } from '@utmb/design-system/icons/Icon';
import { Image } from '@utmb/design-system/image/Image';
import { Overlay, DismissButton } from '@utmb/design-system/overlay/Overlay';
import { Separator } from '@utmb/design-system/separator/Separator';
import { Spacer } from '@utmb/design-system/spacer/Spacer';
import { XY } from '@utmb/design-system/xy/XY';
// Features
import { LanguageSelector } from '../language-selector/LanguageSelector';
import { MenuBar } from './mobile-utils/bottom-bar';
import { SocialMedia } from '@utmb/features/layout/social-media/SocialMedia';
import { TopBar } from './mobile-utils/top-bar';
import { UserInformation } from '@utmb/features/my-utmb/user-information/UserInformation';
import MegaNavMobile from '@utmb/features/mega-nav/MegaNavMobile';
// Interfaces
import { ColorTypesEnum } from '@utmb/types/colors';
import { IconsEnum } from '@utmb/types/icons';
import { LayoutProps } from '@utmb/layouts/layout-base';
// Utils
import { useUser } from '@utmb/contexts/AuthContext';
// Styles
import '@reach/accordion/styles.css';
import styles from './mobile-utils/mobile.module.scss';
import { getColorStyle } from '@utmb/utils/colors';
import GlobalSimpleMenuLevelView from '@utmb/types/view/GlobalSimpleMenuLevelView';
import GlobalSimpleMenuLevel2View from '@utmb/types/view/GlobalSimpleMenuLevel2View';
import { MyParameters } from '../my-parameters/MyParameters';
import { useTenancy } from '@utmb/contexts/TenancyContext';
import { PiCategoryLogo } from '@utmb/design-system/pi-category-logo/PiCategoryLogo';
import { PiCategoryEnum } from '@utmb/types/enum/PiCategoryEnum';
import { GlobalEventView } from '@utmb/types/index';

const { FuturaBook, FuturaMedium } = Font;

export const MobileSimple = (
    props: Pick<LayoutProps, 'simpleMenu' | 'event' | 'mobileBottomBar' | 'eventsTopBar' | 'languages' | 'translationSlugs'>
): ReactElement | null => {
    const { t } = useTranslation();
    const { tenantId } = useTenancy();

    const [status, setStatus] = useState(false);
    const close = (): void => {
        setStatus(false);
        setIsMegaNavOpen(false);
    };
    const open = (): void => setStatus(true);

    const isWorldSeries = tenantId === process.env.NEXT_PUBLIC_DEFAULT_TENANCY;

    const { event, simpleMenu, mobileBottomBar, eventsTopBar, languages, translationSlugs } = props;
    const { siteLogoDark } = event;

    // Find menu links elements
    //const menuLinks = simpleMenu?.children.filter((tree: GlobalSimpleMenuLevelView) => !tree.children?.length && tree.slug !== '/');

    const router = useRouter();
    const navigate = async (destination: string): Promise<void> => {
        await router.push(destination);
        close();
    };

    // Get the user
    const { isAuthenticated, me, logout } = useUser();

    const [isMegaNavOpen, setIsMegaNavOpen] = useState(false);

    function handleMegaNavToggle(): void {
        const currentIsMegaNavOpen = !isMegaNavOpen;

        setIsMegaNavOpen(currentIsMegaNavOpen);
    }

    if (isEmpty(mobileBottomBar)) return null;

    const classesHeader = [styles.header, !isAuthenticated && styles.header_not_logged].filter(Boolean).join(' ');

    return (
        <>
            <MenuBar of={mobileBottomBar!} openTray={status ? close : open} isTrayOpen={status} className={styles.fixed} />
            {status && (
                <Overlay.Tray isOpen={true} isDismissable onClose={close} title="Menu">
                    <Col className={styles.scrollable}>
                        <Col className={styles.container}>
                            <TopBar handleMegaNavToggle={handleMegaNavToggle} />
                            <MegaNavMobile isOpen={isMegaNavOpen} eventsTopBar={eventsTopBar} handleMegaNavToggle={handleMegaNavToggle} />

                            <div className={classesHeader}>
                                <MyParameters className={styles.selector} />
                                {siteLogoDark ? (
                                    <div className={styles.logo_container}>
                                        <Image image={siteLogoDark} layout="intrinsic" width={siteLogoDark.width} height={siteLogoDark.height} />
                                    </div>
                                ) : (
                                    <></>
                                )}

                                <LanguageSelector className={styles.selector} languages={languages} translationSlugs={translationSlugs} />
                            </div>
                            {isAuthenticated && me && (
                                <>
                                    <UserInformation user={me} />
                                    <Separator spacing="16px 0" opacity="0.3" />
                                </>
                            )}

                            {simpleMenu?.children[0]?.slug === '/' && !isWorldSeries && (
                                <NonAccordionRow cb={navigate} {...simpleMenu?.children[0]} event={event} />
                            )}

                            <Accordion collapsible>
                                {simpleMenu?.children.map((tree: GlobalSimpleMenuLevelView) => {
                                    if (tree.children && tree.children.length) {
                                        return (
                                            <AccordionItem key={uuidv4()}>
                                                <AccordionRow {...tree} event={event} />
                                                <AccordionPanel className={[styles.panel, styles.panel_simple].join(' ')}>
                                                    {tree.children.length ? (
                                                        <Fragment key={uuidv4()}>
                                                            {tree.children.map((lastTree: GlobalSimpleMenuLevel2View) => {
                                                                return (
                                                                    <Button.Ghost
                                                                        className={styles['flex-start']}
                                                                        key={uuidv4()}
                                                                        onPress={() => navigate(lastTree.slug!)}
                                                                        link={tree.slug}
                                                                    >
                                                                        <FuturaBook mobile="16">{lastTree.label?.toUpperCase()}</FuturaBook>
                                                                    </Button.Ghost>
                                                                );
                                                            })}
                                                        </Fragment>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    <Separator color={getColorStyle(ColorTypesEnum.WHITE)} spacing="12px 0" />
                                                </AccordionPanel>
                                            </AccordionItem>
                                        );
                                    } else if (tree.slug !== '/') {
                                        return <NonAccordionRow key={uuidv4()} cb={navigate} {...tree} event={event} />;
                                    }
                                })}
                            </Accordion>

                            {/*!!menuLinks &&
                                menuLinks.map((tree: GlobalSimpleMenuLevelView) => {
                                    return <NonAccordionRow key={uuidv4()} cb={navigate} {...tree} />;
                                })*/}
                            <Spacer.size30 />
                            <SocialMedia colorTheme={ColorTypesEnum.WHITE} />
                            <Spacer custom="40" />
                            <Separator className={styles.separator} color="var(--utmb-color-border-gray)" />

                            {isAuthenticated && (
                                <a href="#" className={styles.menu_user_logout} onClick={logout}>
                                    <Icon variant={IconsEnum.LOGOUT} />
                                    <Font.FuturaBook className={styles.menu_user_logout_link} mobile="12" desktop="12" as="span">
                                        {t('common.signOut')}
                                    </Font.FuturaBook>
                                </a>
                            )}
                            <Spacer custom="100" />
                        </Col>
                    </Col>
                    <DismissButton onDismiss={close} />
                </Overlay.Tray>
            )}
        </>
    );
};

interface AccordionRowProps extends GlobalSimpleMenuLevelView {
    event: GlobalEventView;
}

const AccordionRow = (tree: AccordionRowProps) => {
    const { isExpanded } = useAccordionItemContext();
    const { tenantId } = useTenancy();

    const event = tree.event;

    const isWorldSeries = tenantId === process.env.NEXT_PUBLIC_DEFAULT_TENANCY;

    return (
        <h3>
            <AccordionButton className={styles.row}>
                {tree.icon ? <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={tree.icon} /> : <div className={styles.emptyIcon} />}

                <FuturaMedium mobile="22" as="span" className={styles.spaced}>
                    {tree.label?.toUpperCase() === 'UTMBINDEX' ? (
                        <PiCategoryLogo height={24} piCategory={PiCategoryEnum.GENERAL} />
                    ) : isWorldSeries && tree.label?.toUpperCase() === 'UTMBWORLDSERIES' ? (
                        <div className={styles.utmbws}>
                            <Image objectFit="contain" image={event.siteLogoDark} maxWidth={100} />
                        </div>
                    ) : (
                        tree.label?.toUpperCase()
                    )}
                </FuturaMedium>
                <XY xy={isExpanded ? 'rotate(-180deg) translate(-2px, 2px)' : 'rotate(0) translate(2px, 0)'}>
                    <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={IconsEnum.CHEVRON} />
                </XY>
            </AccordionButton>
        </h3>
    );
};

/**
type SpecialProps = {
    label: string;
    defaultLabel?: string;
};

const AccordionRowSpecial = (props: SpecialProps): ReactElement => {
    const { defaultLabel, label } = props;
    const { isExpanded } = useAccordionItemContext();
    return (
        <>
            {defaultLabel && (
                <FuturaBold mobile="24" as="span">
                    {defaultLabel.toUpperCase()}
                </FuturaBold>
            )}
            <h3>
                <AccordionButton className={styles.row}>
                    <FuturaMedium mobile="24" as="span">
                        {label.toUpperCase()}
                    </FuturaMedium>
                    <XY xy={isExpanded ? 'rotate(-180deg) translate(-2px, 2px)' : 'rotate(0) translate(2px, 0)'}>
                        <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={IconsEnum.CHEVRON} />
                    </XY>
                </AccordionButton>
            </h3>
        </>
    );
};
*/

interface NonAccordionRowProps extends AccordionRowProps {
    cb: (slug: string) => void;
}

const NonAccordionRow = (props: NonAccordionRowProps): ReactElement => {
    const { cb, label, slug, icon, event } = props;

    const { tenantId } = useTenancy();

    const isWorldSeries = tenantId === process.env.NEXT_PUBLIC_DEFAULT_TENANCY;

    return (
        <h3>
            <Button.Ghost onPress={() => cb(slug ? slug : '')} className={styles.generic} link={slug}>
                {icon ? <Icon color={getColorStyle(ColorTypesEnum.WHITE)} variant={icon!} /> : <span />}
                <FuturaMedium mobile="24" as="span">
                    {label?.toUpperCase() === 'UTMBINDEX' ? (
                        <PiCategoryLogo height={24} piCategory={PiCategoryEnum.GENERAL} />
                    ) : isWorldSeries && label?.toUpperCase() === 'UTMBWORLDSERIES' ? (
                        <div className={styles.utmbws}>
                            <Image objectFit="contain" image={event.siteLogoDark} maxWidth={100} />
                        </div>
                    ) : (
                        label?.toUpperCase()
                    )}
                </FuturaMedium>
            </Button.Ghost>
        </h3>
    );
};
